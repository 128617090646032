<!-- =========================================================================================
    File Name: StatisticsCard.vue
    Description: Statistics card component
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard

    
      Author:  
    Author URL:  
========================================================================================== -->

<template>
  <vx-card class="overflow-hidden">
    <div slot="no-body" class="p-5">
      <div class="pb-3" :class="{'flex justify-between flex-row-reverse items-center': iconRight}">
        <div class="d-flex align-items-center">
          <feather-icon
            :icon="icon"
            class="p-3 inline-flex rounded-full"
            :class="[`text-${color}`]"
            :style="{background: `rgba(var(--vs-${color}),.15)`}"
          ></feather-icon>
          <h2 class="mb-1 font-bold ml-3">{{ titleName }}</h2>
        </div>
      </div>

      <div class="vx-row flex-col-reverse lg:flex-row">
        <div class="vx-col w-full flex flex-col">
          <h5 class>{{ deptName }}</h5>
          <h1 class="font-bold text-5xl">{{ deptNum }}</h1>
        </div>
      </div>
      <div class="text-right">
        <router-link :to="{ path: routeName }">More..</router-link>
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
    },
    chartData: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    chartType: {
      type: String,
      default: "line-chart",
    },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "",
    },
    deptName: {
      type: String,
      default: "",
    },
    deptNum: {
      type: String,
      default: "0",
    },
    routeName: {
      type: String,
      default: "/",
    },
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex() } },
      });
    },
  },
  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },
  methods: {
    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(",");
      return (
        "#" +
        (
          (1 << 24) +
          (Number(rgb[0]) << 16) +
          (Number(rgb[1]) << 8) +
          Number(rgb[2])
        )
          .toString(16)
          .slice(1)
      );
    },
  },
  components: {
    VueApexCharts,
  },
  created() {
    if (this.type == "area") {
      this.chartData.chartOptions["theme"] = {
        monochrome: {
          enabled: true,
          color: this.getHex(this.color),
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
    }
  },
};
</script>